import { Dropdown, Modal } from 'bootstrap';
import('./sidebar.js');

var getUserMedia = require('get-user-media-promise');
export { getUserMedia };

function fullscreen_image(event) {
    var src_img = event.target;
    var mid = src_img.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.querySelector('.module-mid').innerHTML;
    var installation_date = src_img.getAttribute('aria-installationtime');
    var modal_div = document.getElementById('image-modal');
    var title = modal_div.querySelector('.modal-title');
    title.innerHTML = "Installation image on "+mid+" from "+installation_date;

    var inner_img = modal_div.querySelector('.full-image');
    inner_img.setAttribute('src', src_img.getAttribute('src'));

    var download_button = modal_div.querySelector('.btn-primary');
    download_button.setAttribute('href', src_img.getAttribute('src'));


    var modal = new Modal(document.getElementById('image-modal'), {
        keyboard: false
    });
    modal.show();
}

export { fullscreen_image };
